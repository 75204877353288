$(document).ready(function () {
  $(".select_box input").click(function () {
    if ($(this).hasClass("one_option")) {
      $(".select_box").removeClass("active");
      $(this).parent().addClass("active");
    } else {
      $(this).parent().toggleClass("active");
    }
  });

  function updatePromotionPrices() {
    var promotion_duration = $('input[name="promotion_option"]:checked').val();
    var price = Number(
      $('input[name="promotion_option"]:checked').attr("data-price")
    );

    var premium_selected = $("#promotion_premium").is(":checked");
    var premium_price;

    if (premium_selected) {
      premium_price = Number($("#promotion_premium").attr("data-price"));
    }

    price = price * promotion_duration;

    $("#promotion-price").text("€" + price.toFixed(2));
    $("#promotion-vat").text("€" + (price * 0.21).toFixed(2));
    $("#promotion-total").text("€" + (price * 1.21).toFixed(2));
  }

  // Call the price calculation function on page load
  updatePromotionPrices();

  // @Desc: Event handles for if the promotion date inputs changes
  $('input[name="promotion_option"]').on("change", function () {
    // Visually update the selected option in the overview
    var promotion_duration = $('input[name="promotion_option"]:checked').val();
    $("#days-amount-value").text(promotion_duration);

    // Set the end date of the campaign to 14 or 28 days from the start date
    var start_date = new Date($("#promote-start-date").val());
    var end_date = new Date(
      start_date.getTime() + $(this).val() * 24 * 60 * 60 * 1000
    );

    // Split the date into yyyy-mm-dd format for the input
    var dd = String(end_date.getDate()).padStart(2, "0");
    var mm = String(end_date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = end_date.getFullYear();

    // Set the end date input value
    end_date = yyyy + "-" + mm + "-" + dd;

    // Update form input
    $("#promote-end-date").val(end_date);
    $("#promote-end-date").attr("min", end_date);
    $("#end-date-overview").text(dd + "-" + mm + "-" + yyyy);

    // Update prices
    updatePromotionPrices();
  });

  // Event listener for the premium checkbox
  $("#promotion_premium").on("change", function () {
    if ($(this).is(":checked")) {
      $("#premium-mode-selected").text("Ja");
    } else {
      $("#premium-mode-selected").text("Nee");
    }

    // Update prices
    updatePromotionPrices();
  });

  // Event listener for the start date (promote-start-date)
  $("#promote-start-date").on("change", function () {
    calculateEndDate();
  });

  // Event listener for the end date (promote-end-date)
  $("#promote-end-date").on("change", function () {
    var start_date = new Date($("#promote-start-date").val());
    var end_date = new Date($("#promote-end-date").val());

    var promotion_duration = (end_date - start_date) / (24 * 60 * 60 * 1000);

    var maxEndDate = new Date(
      start_date.getTime() +
        $('input[name="promotion_option"]:checked').val() * 24 * 60 * 60 * 1000
    );

    if (end_date > maxEndDate) {
      alert(
        "De promotie duur kan niet langer zijn dan " +
          promotion_duration +
          " dagen, met de huidige startdatum kan de promotie lopen tot " +
          maxEndDate.toLocaleDateString("nl-NL")
      );
      return false;
    }
  });

  // Function to calculate the end date based on the start date and promotion duration
  function calculateEndDate() {
    var promotion_duration = $('input[name="promotion_option"]:checked').val();

    if (!promotion_duration) {
      return; // Exit if promotion duration is not selected
    }

    var start_date = new Date($("#promote-start-date").val());
    var end_date = new Date(
      start_date.getTime() + promotion_duration * 24 * 60 * 60 * 1000
    );

    // Split the date into yyyy-mm-dd format for the input
    var dd = String(end_date.getDate()).padStart(2, "0");
    var mm = String(end_date.getMonth() + 1).padStart(2, "0"); // January is 0!
    var yyyy = end_date.getFullYear();

    // Set the end date input value
    end_date = yyyy + "-" + mm + "-" + dd;

    // Update form input
    $("#promote-end-date").val(end_date);
    $("#end-date-overview").text(dd + "-" + mm + "-" + yyyy);
    $("#start-date-overview").text(
      $("#promote-start-date").val().split("-").reverse().join("-")
    );
    $("#promote-end-date").attr("min", end_date);
  }

  // Event listener for the payment button
  $(".promote-pay-button").on("click", function () {
    var promotion_duration = $('input[name="promotion_option"]:checked').val();

    if (!promotion_duration) {
      alert("Selecteer een promotie optie");
      return false;
    }

    // Create a date object from the start and end date inputs
    var start_date = new Date($("#promote-start-date").val());
    var end_date = new Date($("#promote-end-date").val());

    var maxEndDate = new Date(
      start_date.getTime() + promotion_duration * 24 * 60 * 60 * 1000
    );

    // Create an alert if the dates are somehow invalid
    if (end_date > maxEndDate) {
      alert(
        "De promotie duur kan niet langer zijn dan " +
          promotion_duration +
          " dagen, met de huidige startdatum kan de promotie lopen tot " +
          maxEndDate.toLocaleDateString("nl-NL")
      );
      return false;
    }

    // Get the ?job_id= parameter from the URL
    var urlParams = new URLSearchParams(window.location.search);
    var job_id = urlParams.get("job_id");

    if (!job_id) {
      alert("Er is geen vacature ID gevonden");
      return false;
    }

    // Get the user form data
    var billing_first_name = $("#billing_first_name").val();
    var billing_last_name = $("#billing_last_name").val();
    var billing_company = $("#billing_company").val();
    var billing_address_1 = $("#billing_address_1").val();
    var billing_city = $("#billing_city").val();
    var billing_postcode = $("#billing_postcode").val();
    var billing_country = $("#billing_country").val();

    // Check if all the required fields are filled in
    if (
      !billing_first_name ||
      !billing_last_name ||
      !billing_address_1 ||
      !billing_city ||
      !billing_postcode ||
      !billing_country
    ) {
      alert("Vul geldige factuur gegevens in");
      return false;
    }

    // Make a post request to the same page with the form data to initialize the payment
    $.ajax({
      type: "POST",
      url: window.location.href,
      data: {
        action: "promote_post",
        promotion_duration: promotion_duration,
        premium_selected: $("#promotion_premium").is(":checked"),
        start_date: $("#promote-start-date").val(),
        end_date: $("#promote-end-date").val(),
        job_id: job_id,
        billing_first_name: billing_first_name,
        billing_last_name: billing_last_name,
        billing_company: billing_company,
        billing_address_1: billing_address_1,
        billing_city: billing_city,
        billing_postcode: billing_postcode,
        billing_country: billing_country
      },
      success: function (response) {
        // Check if response includes https
        if (response.includes("https")) {
          // Redirect to the payment page
          $(location).attr("href", response);
        }
      }
    });
  });

 
});
